import React, { useEffect, useState } from "react";
import "./index.scss";
import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AautiUpload from "../../globalComponents/AautiUpload";
import { LuUpload } from "react-icons/lu";
import CourseOverview from "./CourseOverview";
import CreateCourse3 from "../createCourse/CreateCourse3";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate, useBeforeUnload } from "react-router-dom";
import {
  ChatGptFunc,
  getAautiClassPricing,
  getAllLanguages,
  saveDraftCourse,
  saveDraftGig,
  updateDraftCourse,
  // getSpConflicts,
} from "../createCourse/Services";
import { getAllCategories } from "../courseDetails/Services";
import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  setRef,
} from "@mui/material";
import CourseInPerson from "../createCourse/CourseInperson";
import CreateCourse2 from "../createCourse/CreateCourse2";
import CoursePricing from "./CoursePricing";
import {
  getNextTimeSlot,
  levelStrings,
  targetedAgeGroup,
} from "../createCourse/Constants";
import CourseImages from "./CourseImages";
import NavButton from "./NavButton";
import Tab from "./Tab";
import ActiveOverviewTab from "./ActiveOverviewTab";
import JoinButton from "../../globalComponents/JoinButton";
import GigImageFields from "../createCourse/gigCreation/GigImageFields";
import _, { clone, indexOf, isEmpty, sortBy } from "lodash";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import GigPricing from "../createCourse/gigCreation/GigPricing";
import { showToast } from "../../globalComponents/Toast";
import {
  editCourseObject,
  editGigObject,
  setDraftCourseObject,
} from "../../../redux/reducer/appReducer";
import PreviewCourse from "./PreviewCourse";
import HelperText from "./HelperText";
import {
  formatDatesAndTimes,
  PriceConversions,
  sortClassType,
  timeZone,
  validateUrl,
} from "../../CommonFunctions";
import AautiDialog from "../../globalComponents/AautiDialog";
import RefundConditions from "./RefundConditions";
import { GoDotFill } from "react-icons/go";
import ConflictCard from "../ConflictCard";
import { getSpConflicts } from "../../../service/auth-service";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { courseConstant } from "./courseConstant";
import RefundSectionView from "../courseDetails/RefundSectionView";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BottomSheet from "../../globalComponents/BottomSheet";
import ReturnPolicy from "../../conditions/ReturnPolicy";
import { checkNull } from "../../../utils/Helpers";
import { IoIosArrowBack, IoIosCloseCircleOutline } from "react-icons/io";
import GigPreview from "../../gig/GigPreview";
import PreviewGigSubmit from "../../gig/PreviewGigSubmit";
import { FaArrowRight } from "react-icons/fa6";

const date = new Date();
const today = moment(date).format("YYYY-MM-DDT00:00:00");
let endDateFormat = moment(date)?.format("YYYY-MM-DDT23:59:59");

const commonBatchObject = {
  instructors: [],
  instructorDetails: [],
  batchName: "",
  startDate: today,
  endDate: today,
  discountStartDate: today,
  discountEndDate: endDateFormat,
  discountType: "Percentage",
  inPerson: false,
  virtual: false,
};

const tabsList = [
  {
    id: 1,
    name: "Title",
    text: "Course creation offers you the flexibility to select categories such as Levels, Category, Type, Location, Age, Description, and Tags, and allows you to divide the sessions accordingly. ",
  },
  {
    id: 2,
    name: "Lesson Plan",
    text: "You can either create a Lesson Plan or choose from existing options, with additional ways to personalize your selection. ",
  },

  {
    id: 4,
    name: "Course Type & Pricing",
    text: "Set pricing and discounts for individual or group slots. Adjust rates based on the number of participants and the type of session to offer flexible pricing options. ",
  },
];

let subTopicObject = {
  subTopicName: "",
  subTopicDescription: "",
  contentData: "",
  uploadContent: [],
  uploadDocument: [],
};
let topicObject = {
  type: "Lesson",
  topicName: "",
  topicDescription: "",
  data: [],
};

let sessionObject = {
  sessionName: "Session 1",
  sessionDescription: "",
  topics: [topicObject],
};

const CourseCreation = () => {
  const [activeField, setActiveField] = useState("");
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [openAddress, setOpenAddress] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [activeSession, setactiveSession] = useState(0);
  const [activeTopic, setactiveTopic] = useState(0);
  const [activeSubTopic, setactiveSubTopic] = useState(0);
  const [conflictsList, setConflictsList] = useState(null);
  const [refundPolicyState, setRefundPolicyState] = useState(false);
  const [dialoTitle, setDialogTitle] = useState("");
  const date = new Date();
  const [showDraftPop, setShowDraftPop] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [activeBatch, setActiveBatch] = useState(10);
  const today = moment(date).format("YYYY-MM-DDT00:00:00");
  let endDateFormat = moment(date)?.format("YYYY-MM-DDT23:59:59");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const nextTimeSlot = getNextTimeSlot();
  const {
    loggedinUserId,
    editCourse,
    userDetails,
    editGig,
    draftCourse,
    cloneCourse,
    countriesArray,
    countryObject,
    properties,
  } = useSelector((state) => state.app);
  const { titleMaximumLength = 70, titleMinimumLength = 15 } = properties;
  const activeSlot =
    activeBatch == 10 || activeBatch == 30
      ? {
          ...commonBatchObject,
          startTime: nextTimeSlot,
          endTime: moment(nextTimeSlot)
            ?.add(1, "hour")
            ?.format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {
          ...commonBatchObject,
          recurringDays: {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
          },
        };
  const [courseBatch, setCourseBatch] = useState({ ...activeSlot });

  const { onlyPriceConvert, convertIndianAmount } = PriceConversions();
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const [showPreview, setShowPreview] = useState(false);
  const [ASPrincing, setASPring] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [saveApiLoad, setSaveApiLoad] = useState(false);
  const [openRefundConditions, setOpenRefundConditions] = useState(false);
  const [refundConditions, setRefundConditions] = useState(
    editCourse
      ? editCourse?.refundConditions || []
      : userDetails?.courseRefundConditions[0]?.refundConditions || []
  );

  const userCountry = _.filter(countriesArray, {
    name: userDetails?.country,
  });

  const gb = {
    gigImage: editGig ? editGig?.gigImage : "",
    shortVideoUrl: editGig ? editGig?.shortVideoUrl : "",
    serviceProviderDisplayName: "",
    serviceProviderId: "",
    serviceProviderName: "",
    profileImage: "",
    gigPrice: editGig ? editGig?.gigPrice : 0,
    localGigPrice: editGig
      ? editGig?.localGigPrice
        ? editGig?.localGigPrice
        : onlyPriceConvert(editGig?.gigPrice)
      : 0,
    relatedDocuments: [],
    gigVideo: "",
    gigTopics: [],
    gigVideos: editGig ? editGig?.gigVideos : [],
    discountStartDate: editGig
      ? moment(editGig?.discountStartDate).format("YYYY-MM-DDT00:00:00")
      : today,
    discountEndDate: editGig?.discountEndDate
      ? moment(editGig?.discountEndDate).format("YYYY-MM-DDT00:00:00")
      : endDateFormat,
    discountType: "percentage",
    gender: isEmpty(editGig?.gender) ? ["Male", "Female"] : editGig?.gender,
    localGigDiscountValue: editGig ? editGig?.localGigDiscountValue : 0,
    gigDiscountValue: editGig ? editGig?.gigDiscountValue : 0,
    localDiscountPrice: editGig ? editGig?.localDiscountPrice : 0,
    discountPrice: editGig ? editGig?.discountPrice : 0,

    countryFlag:
      "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Flag_of_India.svg.png",
  };
  const isUpdate = !checkNull(editCourse) || !checkNull(editGig);
  const [gigObject, setGigObject] = useState(gb);
  const itemsWithNewComponent = [...tabsList];
  itemsWithNewComponent.splice(2, 0, { isComponent: true });

  const [planObj, setPlanObj] = useState({
    contentName: "",
    contentDescription: "",
    category: "",
    subCategory: "",
    level: [],
    duration: 0,
    noOfSessions: 1,
    noOfTopics: 1,
    noOfSubTopics: 0,
    serviceProviderId: loggedinUserId,
    sessions: [sessionObject],
  });
  const dispatch = useDispatch();
  const [courseOverviewErrors, setCourseOverviewErrors] = useState({});
  const [courseImageErrors, setCourseImageError] = useState({});
  const location = useLocation();
  const [open2, setOpen2] = useState(false);
  const prevData = localStorage.getItem("CreateCourseObj");

  const defaultLan = editGig
    ? editGig?.languages
    : isEmpty(userDetails?.preferredLanguages)
    ? ["English"]
    : userDetails?.preferredLanguages;
  const obj = {
    serviceProviderId: loggedinUserId,
    courseLevel: editGig
      ? editGig?.courseLevel?.includes("All Levels")
        ? levelStrings
        : editGig?.courseLevel
      : levelStrings,
    category: editGig ? editGig?.category : "",
    subCategory: editGig ? editGig?.subCategory : "",
    languages: defaultLan,
    groupLanguage: userDetails?.preferredLanguages?.[0] ?? "English",
    courseType: editGig || location.state == "Gig" ? ["Gig"] : [],
    searchTags: editGig ? editGig?.searchTags : [],
    inPerson: false,
    virtual: true,
    sponsoredCourse: false,
    blockbusterDeal: false,
    isClientLocation: false,
    radius: 0,
    radiusType: "Km",
    targetAgeGroups: editGig ? editGig?.targetAgeGroups : targetedAgeGroup,
    courseName: editGig ? editGig?.gigName : "",
    courseDescription: editGig ? editGig?.gigDescription : "",
    summary: editGig ? editGig?.summary : "",
    courseImage: "",
    relatedImages: editGig ? editGig?.relatedImages : [],
    gender: !isEmpty(editGig?.gender) ? editGig?.gender : ["Male", "Female"],
    relatedDocuments: [],
    lessonPlan: {},
    sessionsCount: 1,
    recurrenceType: [],
    individualWeekly: [],
    individualDaily: [],
    groupWeekly: [],
    groupDaily: [],
    averageRating: 0,
    ratingCount: 0,
    address: "",
    timeZone: moment().utcOffset(),
    deleted: false,
    bookCoverPage: [],
    refundConditions: [],
    isRefundAvailable: false,

    // currencyCode:,
  };

  const resetObject = {
    serviceProviderId: loggedinUserId,
    courseLevel: levelStrings,
    category: "",
    subCategory: "",
    languages: defaultLan,
    groupLanguage: userDetails?.preferredLanguages?.[0] ?? "English",
    courseType: location.state == "Gig" ? ["Gig"] : [],
    searchTags: [],
    inPerson: false,
    virtual: false,
    sponsoredCourse: false,
    blockbusterDeal: false,
    isClientLocation: false,
    radius: 0,
    radiusType: "Km",
    targetAgeGroups: targetedAgeGroup,
    courseName: "",
    courseDescription: "",
    summary: "",
    courseImage: "",
    relatedImages: [],
    gender: userDetails?.gender ? [userDetails?.gender] : ["Male", "Female"],
    relatedDocuments: [],
    lessonPlan: {},
    sessionsCount: 1,
    recurrenceType: [],
    individualWeekly: [],
    individualDaily: [],
    groupWeekly: [],
    groupDaily: [],
    averageRating: 0,
    ratingCount: 0,
    address: "",
    addressId: "",
    timeZone: moment().utcOffset(),
    deleted: false,
    bookCoverPage: [],
    refundConditions: [],
    isRefundAvailable: false,
    // currencyCode:,
  };
  const [CreateCourseObj, setCreateCourseObj] = useState(
    editCourse
      ? {
          ...editCourse,
          courseLevel: editCourse?.courseLevel?.includes("All Levels")
            ? levelStrings
            : editCourse?.courseLevel,
        }
      : draftCourse
      ? {
          ...draftCourse,
          courseLevel: draftCourse?.courseLevel?.includes("All Levels")
            ? levelStrings
            : draftCourse?.courseLevel,
        }
      : cloneCourse
      ? {
          ...cloneCourse,
          courseLevel: cloneCourse?.courseLevel?.includes("All Levels")
            ? levelStrings
            : cloneCourse?.courseLevel,
          gender: cloneCourse?.gender || ["Male", "Female"],
          lessonPlan: cloneCourse?.lessonPlan
            ? cloneCourse?.lessonPlan
            : {
                // name: cloneCourse?.courseName,
                // description: cloneCourse?.courseDescription,
              },
          individualDaily: [],
          individualWeekly: [],
          groupDaily: [],
          groupWeekly: [],
          isRefundAvailable: false,
          refundConditions: [],
          endUserList: [],
          ratingCount: 0,
          averageRating: 0,
        }
      : obj
  );

  const [open, setOpen] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const isGig = CreateCourseObj?.courseType?.includes("Gig");

  const userPurchased =
    courseBatch?._id &&
    !isEmpty(CreateCourseObj?.endUserList) &&
    CreateCourseObj?.endUserList?.some(
      (batch) => batch.batchId === courseBatch?._id
    );

  const defaultAddress = userDetails?.addressList?.filter(
    (each) => each?.isDefault == true
  );

  useEffect(() => {
    apiCalls();
    if (pathname == "/update-gig") {
      setCreateCourseObj((prev) => ({
        ...prev,
        courseType: ["Gig"],
      }));
    }
  }, []);

  useEffect(() => {
    if (CreateCourseObj?.category && CreateCourseObj?.subCategory) {
      getAautiClassPricing(
        loggedinUserId,
        CreateCourseObj?.category,
        CreateCourseObj?.subCategory,
        userDetails?.country,
        (res) => {
          if (res?.status == "Success") {
            setASPring(res?.result);
          }
        },
        (err) => {}
      );
    }
  }, [CreateCourseObj?.category, CreateCourseObj?.subCategory]);

  const draftCourse2 = (obj) => {
    const draftObj = { ...obj, courseLevel: formatSrings(obj?.courseLevel) };
    const {
      searchTags,
      subCategory,
      category,
      relatedImages,
      courseName,
      courseDescription,
      languages,
      targetAgeGroups,
      courseLevel,
      summary,
    } = CreateCourseObj;
    const {
      gigImage,
      shortVideoUrl,
      gigVideos,
      gigPrice,
      discountType,
      discountStartDate,
      discountEndDate,
      localGigPrice,
      localGigDiscountValue,
    } = gigObject;
    const gigDiscountPrice = returnDiscPric();
    const gigObj = {
      gigName: courseName,
      gigDescription: courseDescription,
      createdBy: loggedinUserId,
      searchTags: searchTags,
      category: category,
      subCategory: subCategory,
      gigImage: gigImage,
      shortVideoUrl: shortVideoUrl,
      serviceProviderDisplayName: userDetails?.displayName,
      serviceProviderId: loggedinUserId,
      serviceProviderName: userDetails?.firstName,
      relatedImages: relatedImages,
      profileImage: userDetails?.profileImage,
      gigPrice: gigPrice,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      localGigPrice,
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      summary: summary,
      discountEndDate,
      discountStartDate,
      discountType,
      gigVideos,
      languages,
      targetAgeGroups,
      courseLevel: formatSrings(courseLevel),
      currencyCode: userDetails?.transactionCurrencyCode || "",
      gender: CreateCourseObj?.gender?.map((each) => each?.toLowerCase()),
    };
    const objectSave = editGig ? { _id: editGig?._id, ...gigObj } : gigObj;
    if (isGig) {
      saveDraftGig(
        objectSave,
        (response) => {
          showToast("info", response?.message);
          navigate("/view-gigs", { state: { isDraft: true }, replace: true });
          dispatch(editGigObject(null));
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      saveDraftCourse(
        draftObj,
        (response) => {
          showToast("info", response?.message);
          navigate("/my-courses", { state: { isDraft: true }, replace: true });
          dispatch(setDraftCourseObject(null));
          dispatch(editCourseObject(null));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const sameObjects = _.isEqual(CreateCourseObj, editCourse);

  useEffect(() => {
    const handlePopstate = (event) => {
      const sameObjects = _.isEqual(CreateCourseObj, editCourse);

      if (
        !CreateCourseObj.courseName?.length > 0 ||
        !CreateCourseObj.courseLevel?.length > 0 ||
        !CreateCourseObj?.category?.length > 0 ||
        !CreateCourseObj?.subCategory?.length > 0 ||
        sameObjects
      ) {
        // Navigate based on `isGig`
        const redirectPath = isGig ? "/view-gigs" : "/my-courses";
        navigate(redirectPath, { replace: true });
        return;
      }

      // Confirmation prompt based on `isUpdate`
      const confirmationMessage = isUpdate
        ? "Any unsaved data will be lost. Do you wish to continue?"
        : "Do you want to save changes as Drafts?";

      if (window.confirm(confirmationMessage)) {
        if (!isUpdate) {
          draftCourse2(CreateCourseObj); // Save as draft if not updating
        } else {
          const redirectPath = isGig ? "/view-gigs" : "/my-courses";
          navigate(redirectPath, { replace: true });
        }
      } else {
        event.preventDefault(); // Stop navigation if the user cancels
      }
    };

    const handleNavigation = (event) => {
      const sameObjects = _.isEqual(CreateCourseObj, editCourse);

      // If any of the necessary fields are missing or the objects are the same, return early
      if (
        !CreateCourseObj.courseName?.length ||
        !CreateCourseObj.courseLevel?.length ||
        !CreateCourseObj?.category?.length ||
        !CreateCourseObj?.subCategory?.length ||
        sameObjects
      ) {
        return;
      }

      // Check if the event target is within the header or sidebar
      const confirmationMessage = "Do you want to save changes as Drafts?";
      if (event.target.closest("header") || event.target.closest("sidebar")) {
        if (window.confirm(confirmationMessage)) {
          event.preventDefault(); // Prevent navigation
          draftCourse2(CreateCourseObj); // Save as draft
        }
      }
    };

    // Add event listeners for `popstate` and `click`
    window.addEventListener("popstate", handlePopstate);
    document.addEventListener("click", handleNavigation);

    // Prevent back navigation (this should not be in the render cycle)
    window.history.pushState(null, document.title, window.location.href);

    // Clean up event listeners when the component unmounts or dependencies change
    return () => {
      window.removeEventListener("popstate", handlePopstate);
      document.removeEventListener("click", handleNavigation);
    };
  }, [CreateCourseObj, location.pathname]);

  useBeforeUnload(
    React.useCallback(() => {
      if (
        CreateCourseObj?.courseName &&
        CreateCourseObj?.courseDescription &&
        CreateCourseObj?.category &&
        CreateCourseObj?.subCategory &&
        CreateCourseObj?.courseLevel
      ) {
        localStorage.setItem(
          "CreateCourseObj",
          JSON.stringify(CreateCourseObj)
        );
      }
    }, [CreateCourseObj])
  );
  React.useEffect(() => {
    if (prevData != null) {
      setCreateCourseObj(JSON.parse(prevData));
    }
  }, []);

  const apiCalls = () => {
    getAllCategories(
      (response) => {
        if (response?.status === "Success") {
          setAllCategories(response?.result);
          setDataCate(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    getAllLanguages(
      (response) => {
        if (response?.status === "Success") {
          languageSet(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const Virtual = () => {
    if (
      CreateCourseObj?.virtual === true ||
      CreateCourseObj?.inPerson === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const miniFields =
    !CreateCourseObj.courseName?.length ||
    !CreateCourseObj.courseLevel?.length ||
    !CreateCourseObj?.category?.length ||
    !CreateCourseObj?.subCategory?.length ||
    sameObjects;

  const isButtonEnabled =
    CreateCourseObj?.courseName?.length < 49 ||
    CreateCourseObj?.category === "" ||
    CreateCourseObj?.subCategory === "" ||
    CreateCourseObj?.courseLevel?.length === 0 ||
    (CreateCourseObj?.courseType?.includes("Individual") &&
      CreateCourseObj?.languages?.length < 1) ||
    (CreateCourseObj?.courseType?.includes("Group") &&
      CreateCourseObj?.groupLanguage?.length < 1) ||
    CreateCourseObj?.courseType?.length === 0 ||
    (CreateCourseObj?.inPerson && isEmpty(CreateCourseObj?.address)) ||
    CreateCourseObj?.courseDescription?.length < 50 ||
    CreateCourseObj?.summary?.length < 50 ||
    CreateCourseObj?.targetAgeGroups?.length < 1 ||
    (!isGig && Virtual() === false) ||
    CreateCourseObj?.gender?.length < 1;

  const languageSet = (res) => {
    const newList = [];
    res.map((each) => newList.push(each.name));
    setLanguages(newList);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    setCreateCourseObj(obj);
    setOpen2(false);
  };
  const handleDisAgree = () => {
    setOpen2(false);
  };

  const checInpersonVirtual = (obj) => {
    setCourseOverviewErrors((prevErrors) => {
      if (!isGig && !obj.inPerson && !obj.virtual) {
        return {
          ...prevErrors,
          inPersonOrVirtual: "Either 'virtual' or 'in person' must be selected",
        };
      } else {
        const { inPersonOrVirtual, ...updatedErrors } = prevErrors;
        return updatedErrors;
      }
    });
  };

  const changeCreateObj = (val, feild) => {
    setCreateCourseObj((prev) => {
      const obj = {
        ...prev,
        [feild]: val,
      };
      if (feild == "virtual" || feild == "inPerson") {
        checInpersonVirtual(obj);
      }

      return obj;
    });
    const minLengthKeys = ["courseDescription", "summary"];
    if (feild === "isRefundAvailable" || feild === "refundConditions") {
      delete courseOverviewErrors?.[feild];
    } else if (isEmpty(val) && feild !== "virtual" && feild !== "inPerson") {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        [feild]: `${feild} is required`,
      }));
    } else if (feild == "courseName" && val?.length < titleMinimumLength) {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        [feild]: `should be at least ${titleMinimumLength} characters long`,
      }));
    } else if (minLengthKeys?.includes(feild) && val?.length < 50) {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        [feild]: `should be at least 50 characters long`,
      }));
    } else {
      delete courseOverviewErrors?.[feild];
      // setCourseOverviewErrors((prev) => ({
      //   ...prev,
      //   [feild]: ``,
      // }));
    }
  };

  const changePlanObject = (val, feild) => {
    setPlanObj((prev) => ({
      ...prev,
      [feild]: val,
    }));
  };

  const generateAIResp = (field) => {
    const { category, subCategory, courseLevel, courseName } = CreateCourseObj;
    let value = field == "courseDescription" ? "short description" : "summary";
    let levels = courseLevel?.join(",");
    let charLimit = value === "short description" ? 100 : 200;
    let minLength = 50;

    let prompt = `Write a concise ${value} for a course in the category '${category}', including the subcategory '${subCategory}', designed for levels ${levels}. The response must be between ${minLength} and ${charLimit} characters, including spaces. Ensure that the response avoids special characters like colons, quotes, dashes, and strictly adheres to the character limit. Do not use punctuation that separates the title from its description, such as colons.`;

    if (subCategory && courseLevel?.length > 0) {
      handleOpen();
      ChatGptFunc(
        prompt,
        (response) => {
          if (response.status === "Success") {
            changeCreateObj(response?.result, field);
            // setRefresh(!refresh);
            let resp2;
            if (value === "short description") {
              resp2 = response?.result?.slice(0, 100);
              const obj = {
                ...CreateCourseObj?.lessonPlan,
                description: resp2,
              };
              changeCreateObj(obj, "lessonPlan");
            } else {
              resp2 = response?.result?.slice(0, 200);
            }
            setCreateCourseObj((pre) => ({
              ...pre,
              [field]: resp2,
            }));
            delete courseOverviewErrors?.[field];
            // setCourseOverviewErrors((prev) => ({
            //   ...prev,
            //   [field]: "",
            // }));
          } else {
            showToast("info", response?.message);
          }
          handleClose();
        },
        (error) => {
          handleClose();
        }
      );
    } else {
      if (isEmpty(courseLevel)) {
        showToast("info", "Please choose course levels");
      } else if (!subCategory) {
        showToast("info", "Please choose category & subcategory");
      }
    }
  };

  const returnVideoName = (video) => {
    var filename = video.substring(video.lastIndexOf("/") + 1);
    var videoName = filename.split(".").slice(0, -1).join(".");
    return videoName;
  };

  const multipleVideoUpload = async (file, i) => {
    handleOpen();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json.result) {
        // const videoName = await returnVideoName(json?.result);
        let obj = {
          gigHours: 0,
          gigMinutes: 1,
          gigSeconds: 2,
          videoUrl: json?.result,
          videoTitle: "",
          topicsCount: 0,
          topics: [],
        };

        let videosArray = [
          ...(gigObject?.gigVideos ? gigObject?.gigVideos : []),
          obj,
        ];
        console.log(videosArray);
        setActiveIndex(videosArray?.length - 1);
        setGigObject((prev) => ({
          ...prev,
          gigVideos: videosArray,
        }));
      }
      handleClose();
    } catch (error) {
      console.error("Fetch error:", error);
      handleClose();
    }

    handleClose();
  };

  const handleGigImage = async (e, field) => {
    const selectedFile = e.target.files[0];
    // Check if the selected file is not a video

    if (field === "gigVideo") {
      if (selectedFile && !selectedFile.type.startsWith("video/")) {
        showToast("info", "Please upload a valid video file!");
        // Clear the input
        e.target.value = "";
        return;
      } else {
        const { files } = e.target;
        for (let i = 0; i < files.length; i++) {
          multipleVideoUpload(files[i], i);
        }
      }
    } else {
      if (field === "mediaFiles") {
        let data = e.target.files;
        uploadGigImage(data, field);
      } else {
        let data = e.target.files[0];
        uploadGigImage(data, field);
      }
    }
  };

  const uploadGigImage = async (data, field) => {
    handleOpen();
    const formData = new FormData();
    if (field === "mediaFiles") {
      [...data].map((each) => formData.append("files", each));
      formData.append("platform", UploadBaseUrl);
    } else {
      formData.append("file", data);
      formData.append("platform", UploadBaseUrl);
    }
    const pathUrl =
      field === "mediaFiles"
        ? ApiPaths.FILE_UPLOAD_MULTI
        : ApiPaths.FILE_UPLOAD;
    const url = uploadUrl + pathUrl;
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await res.json();
    if (responseJson?.result) {
      if (field === "gigImage" || field === "shortVideoUrl") {
        setGigObject((prev) => ({
          ...prev,
          [field]: responseJson?.result,
        }));
        // setCourseImageError((prev) => ({
        //   ...prev,
        //   [field]: "",
        // }));
        delete courseImageErrors?.[field];
        handleClose();
      } else if (field === "mediaFiles") {
        const relatedImages = [
          ...CreateCourseObj.relatedImages,
          ...responseJson?.result,
        ];
        setCreateCourseObj((prev) => ({
          ...prev,
          relatedImages: relatedImages,
        }));
        handleClose();
      }
    } else {
      showToast("info", "Something went wrong");
      handleClose();
    }

    handleClose();
  };
  const handleImageFields = (field, itemIndex) => {
    if (field === "mediaFiles") {
      const relatedImgs = [...CreateCourseObj?.relatedImages]?.filter(
        (each, index) => itemIndex !== index
      );
      setCreateCourseObj((prev) => ({
        ...prev,
        relatedImages: relatedImgs,
      }));
    } else {
      setGigObject((prev) => {
        const updatedState = { ...prev, [field]: "" };

        if (field === "gigVideo") {
          const filteredVideos = [...gigObject?.gigVideos]?.filter(
            (each, index) => itemIndex !== index
          );
          updatedState.gigVideos = filteredVideos;
        }
        return updatedState;
      });
    }
  };

  function convertINRtoUSD(amountInINR) {
    const returnAmount = convertIndianAmount(amountInINR);
    return returnAmount || 0;
  }

  const handleDate = (date, field) => {
    const dateFormat = moment(date?.$d).format("YYYY-MM-DDT00:00:00");
    let endDateFormat = moment(date?.$d)?.format("YYYY-MM-DDT23:59:59");

    setGigObject((prev) => ({
      ...prev,
      [field]: dateFormat,
    }));
    if (
      field === "discountStartDate" &&
      moment(dateFormat).isAfter(moment(gigObject?.discountEndDate))
    ) {
      setGigObject((prev) => ({
        ...prev,
        discountEndDate: endDateFormat,
      }));
    }
  };

  const handleChangePricing = (value, field) => {
    const { localGigPrice, discountType } = gigObject;
    setGigObject((prev) => {
      const state = { ...prev };
      if (field === "discountType") {
        state.discountType = value;
      } else if (field == "localGigPrice" || field == "gigPrice") {
        state.localDiscountPrice = 0;
        state.localGigDiscountValue = 0;
        state.gigDiscountValue = 0;
        state.discountPrice = 0;
        state[field] = Number(value);
      } else if (
        field == "localGigDiscountValue" ||
        field == "gigDiscountValue"
      ) {
        let amount;
        if (discountType == "percentage") {
          const discount = (localGigPrice * value) / 100;
          amount = localGigPrice - discount;
        } else {
          amount = localGigPrice - value;
        }
        state.discountPrice = value ? convertINRtoUSD(amount || 0) : 0;
        state.localDiscountPrice = value ? amount : 0;
        state[field] = Number(value);
      } else {
        state[field] = Number(value);
      }

      return state;
    });
  };

  const serviceProviderConflicts = (type, data) => {
    console.log(data, "data?..", type);
    const zones = timeZone();
    let conflict = {
      serviceProviderId: loggedinUserId,
      startDate: moment(moment(data.startDate).format("YYYY-MM-DD"))
        .utc(true)
        .format(),
      endDate: moment(moment(data.endDate).format("YYYY-MM-DD"))
        .local()
        .endOf("day")
        .utc()
        .format(),
      excludedDates: [],
      timeZone: zones.localZone,
      offset: moment().utcOffset(),
      zone: zones.opposite,
      customDates: {},
    };

    const offset = moment().utcOffset();

    if (type == "Daily") {
      conflict.timeSlots = data?.dailyTimeSlots
        ? [...data?.dailyTimeSlots]
        : [];
      conflict.recurrenceType = "daily";
      // return conflict;
    } else if (type == "Custom") {
      conflict.timeSlots = [
        {
          startTime: moment(data?.startTime).local().format(),
          endTime: moment(data?.endTime).local().format(),
        },
      ];
      conflict.customDates = data?.customDates ? data?.customDates : {};
      conflict.excludedDates = data?.excludedDates || [];
      conflict.recurrenceType = "daily";
    } else {
      let weekData = {
        ["Sunday"]: [],
        ["Monday"]: [],
        ["Tuesday"]: [],
        ["Wednesday"]: [],
        ["Thursday"]: [],
        ["Friday"]: [],
        ["Saturday"]: [],
      };
      data?.weekSlots?.forEach((day, index) =>
        day.slots.forEach((each) => {
          weekData[day.day].push({
            startTime: moment(each.startTime).local().format(),
            endTime: moment(each.endTime).local().format(),
          });
        })
      );
      conflict = { ...conflict, ...weekData, recurrenceType: "weekly" };
    }

    handleOpen();
    getSpConflicts(
      conflict,
      (res) => {
        handleClose();
        if (res?.status == "Success" && res?.result) {
          if (res?.result?.length > 0) {
            setConflictsList(res?.result);
          } else {
            showToast("info", "No Conflicts found");
          }
        } else {
          showToast("info", res?.message);
        }
      },
      (err) => {
        handleClose();
        showToast("info", "No Conflicts found");
        console.log(err, "---error");
      }
    );
    handleClose();
  };

  const checkYoutubeVideo = (url) => {
    const urlValidate =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
    return urlValidate.test(url);
  };

  const validateObj = (obj) => {
    const errors = {};
    const imageErrors = {};
    // Validate array fields
    const arrayKeys = [
      "courseLevel",
      "languages",
      "courseType",
      "targetAgeGroups",
      "gender",
    ];
    arrayKeys.forEach((key) => {
      if (obj[key]?.length === 0) {
        errors[key] = `${key} should not be empty`;
      }
    });

    // Validate boolean fields
    if (!isGig && !obj.inPerson && !obj.virtual) {
      errors.inPersonOrVirtual =
        "Either 'virtual' or 'in person' must be selected";
    }

    if (obj?.inPerson && isEmpty(obj.address)) {
      errors.inPersonOrVirtual = "Address is mondatory";
    }

    if (obj?.courseName?.length < titleMinimumLength) {
      errors.courseName = `courseName should be at least ${titleMinimumLength} characters long`;
    }

    // Validate string fields
    const stringKeys = ["category", "subCategory"];
    stringKeys.forEach((key) => {
      if (!obj[key]) {
        errors[key] = `${key} should not be empty`;
      }
    });

    // Validate string length for courseName, courseDescription, and summary
    const minLengthKeys = ["courseDescription", "summary"];
    minLengthKeys.forEach((key) => {
      if (!obj[key]) {
        errors[key] = `${key} should not be empty`;
      } else if (obj[key].length < 50) {
        errors[key] = `${key} should be at least 50 characters long`;
      }
    });

    if (!isGig && !obj.courseImage) {
      imageErrors.courseImage = "course image is required";
    }

    if (!isGig && obj?.relatedImages?.length < 2) {
      imageErrors.relatedImages = "minimum 2 related images are required";
    }
    if (isGig && !gigObject?.gigImage) {
      imageErrors.gigImage = "gig image is required";
    }
    if (isGig && !gigObject?.shortVideoUrl) {
      imageErrors.shortVideoUrl = "preview video is required";
    }
    if (isGig && (!gigObject.gigVideos || gigObject?.gigVideos?.length === 0)) {
      imageErrors.gigVideos = "minimum one video is required";
    }

    setCourseOverviewErrors(errors);
    setCourseImageError(imageErrors);
    return { errors, imageErrors };
  };

  // showing error based on field as base
  const handleOnBlurInput = (e, base) => {
    console.log(base, "base");
    if (CreateCourseObj[base]?.length < 50) {
      setCourseOverviewErrors((prev) => ({
        ...prev,
        [base]: `${base} should be at least 50 characters long`,
      }));
    }
  };

  const returnBool =
    gigObject?.gigImage === "" ||
    gigObject?.shortVideoUrl === "" ||
    gigObject?.gigVideos?.length === 0;

  const returnActiveContent = (active) => {
    switch (activeField) {
      case tabsList[0].name:
        return (
          <CourseOverview
            {...{
              categories,
              handleOnBlurInput,
              CreateCourseObj,
              changeCreateObj,
              languages,
              setOpenAddress,
              openAddress,
              planObj,
              sessionObject,
              changePlanObject,
              generateAIResp,
              pathname,
              handleAgree,
              setOpen2,
              open2,
              handleDisAgree,
              setActiveField,
              isGig,
              isButtonEnabled,
              location,
              editCourse,
              draftCourse,
              cloneCourse,
              setCreateCourseObj,
              allCategories,
              defaultAddress,
              courseOverviewErrors,
              validateObj,
              setCourseOverviewErrors,
              resetObject,
              editGig,
            }}
          />
        );
      case tabsList[1].name:
        return (
          <div className={`tab ${miniFields && "disable-field"}`}>
            <IconButton
              sx={{
                position: "absolute",
                right: 2,
                top: 2,
              }}
              onClick={() => {
                setActiveField("");
              }}
            >
              <IoIosCloseCircleOutline />
            </IconButton>
            <AautiText
              size={"medium"}
              weight={"bold"}
              title={AautiStrings?.LessonPlanString}
              textStyle={{ marginBottom: "15px" }}
            />
            <CreateCourse2
              {...{
                CreateCourseObj,
                changeCreateObj,
                planObj,
                setPlanObj,
                changePlanObject,
                sessionObject,
                activeSession,
                activeSubTopic,
                activeTopic,
                setactiveSession,
                setactiveTopic,
                setactiveSubTopic,
                topicObject,
                subTopicObject,
                dialoTitle,
                setDialogTitle,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                }}
              >
                {CreateCourseObj?.lessonPlan?.contentName &&
                CreateCourseObj?.lessonPlan?.contentName !== "" ? (
                  <HelperText title={AautiStrings.LpNotMandatory} />
                ) : (
                  ""
                )}
              </div>
              <NavButton
                text={AautiStrings?.NextString}
                handleClick={() => {
                  // const errObj = validateObj(CreateCourseObj);
                  // if (!isEmpty(errObj?.errors)) {
                  //   setActiveField("Title");
                  // } else {
                  setActiveField("Course Image");
                  // }
                }}
              />
            </div>
          </div>
        );
      case tabsList[2].name:
        return (
          <div style={{ width: "100%" }}>
            {isGig ? (
              <div className="tab">
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 2,
                    top: 2,
                  }}
                  onClick={() => {
                    setActiveField("");
                  }}
                >
                  <IoIosCloseCircleOutline />
                </IconButton>
                <AautiText
                  size={"medium"}
                  weight={"bold"}
                  title={AautiStrings?.GigPricingString}
                  textStyle={{ marginBottom: "15px" }}
                />
                <GigPricing
                  {...{
                    returnRelatedPricing,
                    handleDate,
                    gigObject,
                    handleChangePricing,
                    convertINRtoUSD,
                    editGig,
                  }}
                />
              </div>
            ) : (
              <CoursePricing
                {...{
                  CreateCourseObj,
                  changeCreateObj,
                  convertINRtoUSD,
                  setActiveField,
                  handleOpen,
                  handleClose,
                  returnRelatedPricing,
                  editCourse,
                  serviceProviderConflicts,
                  countryObject,
                  miniFields,
                  validateObj,
                  courseBatch,
                  setCourseBatch,
                  activeBatch,
                  setActiveBatch,
                  activeSlot,
                  userPurchased,
                  commonBatchObject,
                }}
              />
            )}
            {!isGig && (
              <div
                className="tags-container"
                style={{ flexDirection: "column", padding: 15 }}
                onClick={() =>
                  !CreateCourseObj?.isRefundAvailable &&
                  setOpenRefundConditions(true)
                }
              >
                <div
                  style={{
                    gap: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <AautiText title={"Refunds"} weight={"bold"} />
                  <ChevronRightIcon />
                </div>
                <div
                  style={{
                    width: mobileDevices ? "100%" : "50%",
                    marginTop: 10,
                    display:
                      CreateCourseObj?.isRefundAvailable &&
                      !isEmpty(CreateCourseObj?.refundConditions)
                        ? "block"
                        : "none",
                  }}
                >
                  <RefundSectionView
                    completeDetails={CreateCourseObj}
                    onClickRefund={() => setRefundPolicyState(true)}
                    onClickEdit={() => {
                      if (!userPurchased) {
                        setOpenRefundConditions(true);
                        setRefundConditions(CreateCourseObj?.refundConditions);
                      } else {
                        showToast("info", "Users purchased cannot be edited");
                      }
                    }}
                    creation={true}
                    setRefundPolicyState={setRefundPolicyState}
                  />
                </div>
              </div>
            )}
          </div>
        );
      case "Course Image":
        const buttonDisabled =
          CreateCourseObj?.courseImage === "" ||
          CreateCourseObj?.relatedImages?.length < 2;

        return (
          <div className={`tab ${miniFields && "disable-field"}`}>
            <IconButton
              sx={{
                position: "absolute",
                right: 2,
                top: 2,
              }}
              onClick={() => {
                setActiveField("");
              }}
            >
              <IoIosCloseCircleOutline />
            </IconButton>
            <AautiText
              size={"medium"}
              weight={"bold"}
              title={courseConstant.AautiAddImagesAndVideosString}
              textStyle={{ marginBottom: "15px" }}
            />
            {isGig ? (
              <GigImageFields
                {...{
                  handleGigImage,
                  gigObject,
                  handleImageFields,
                  CreateCourseObj,
                  uploadGigImage,
                  courseImageErrors,
                  handleOpen,
                  handleClose,
                  activeIndex,
                  setActiveIndex,
                  setGigObject,
                  setCourseImageError,
                }}
              />
            ) : (
              <CreateCourse3
                {...{
                  CreateCourseObj,
                  changeCreateObj,
                  handleClose,
                  handleOpen,
                  courseImageErrors,
                  setCourseImageError,
                }}
              />
            )}
            <NavButton
              disabled={isGig ? returnBool : buttonDisabled}
              text={AautiStrings.NextString}
              handleClick={() => {
                // const errObj = validateObj(CreateCourseObj);
                // if (isEmpty(errObj?.errors)) {
                setActiveField(tabsList[2].name);
                // }
              }}
            />
          </div>
        );
      default:
        break;
    }
  };

  const setDataCate = (res) => {
    const newList = [];
    res.map((each, index) => newList.push(each.categoryName));
    setCategories(newList);
  };

  const handleSetSelectAddress = (type) => {
    const { isClientLocation } = CreateCourseObj;
    if (isClientLocation) {
      setCreateCourseObj((prev) => ({
        ...prev,
        radiusType: type,
        address: selectedAddress,
      }));
      setOpenAddress(false);
      setSelectedAddress(undefined);
    } else {
      changeCreateObj(selectedAddress, "address");
      setOpenAddress(false);
      setSelectedAddress(undefined);
    }
  };

  const checkPrice = (keyName, ...arrays) => {
    for (const array of arrays) {
      if (array.length === 0) continue;
      for (const item of array) {
        if (item[keyName] === 0) {
          return false;
        }
      }
    }
    return true;
  };

  const returnDiscPric = () => {
    const { discountType, localGigPrice, localGigDiscountValue } = gigObject;

    // if (now.isBetween(start, end, undefined, "[]")) {
    if (discountType == "percentage") {
      const discount = (localGigPrice * localGigDiscountValue) / 100;
      return localGigPrice - discount;
    } else {
      return localGigPrice - localGigDiscountValue;
    }
    // } else {
    //   return localGigPrice;
    // }
  };

  const returnRelatedPricing = (keyProp) => {
    const filteredObject = CreateCourseObj?.courseLevel.reduce((acc, key) => {
      if (ASPrincing?.[key]) {
        acc[key] = ASPrincing[key];
      }
      return acc;
    }, {});
    const minGroupPrice = Object.values(filteredObject).reduce(
      (minPrice, level) => {
        return level[keyProp] < minPrice ? level[keyProp] : minPrice;
      },
      Infinity
    );
    const minGroupPriceObjects = Object.keys(filteredObject).reduce(
      (acc, key) => {
        if (filteredObject[key]?.[keyProp] === minGroupPrice) {
          return filteredObject[key];
        }
        return acc;
      },
      {}
    );
    const returnValue = minGroupPriceObjects
      ? minGroupPriceObjects[keyProp]
      : keyProp == "groupPrice"
      ? 20
      : 30;
    return returnValue || 30;

    // return filteredObject[CreateCourseObj?.courseLevel[0]][keyProp];
  };

  const saveGigApi = () => {
    const {
      searchTags,
      subCategory,
      category,
      relatedImages,
      courseName,
      courseDescription,
      languages,
      targetAgeGroups,
      courseLevel,
      courseType,
      summary,
    } = CreateCourseObj;
    const {
      gigImage,
      shortVideoUrl,
      gigVideos,
      gigPrice,
      discountType,
      discountStartDate,
      discountEndDate,
      localGigPrice,
      localGigDiscountValue,
    } = gigObject;

    const gigDiscountPrice = returnDiscPric();

    const gigObj = {
      gigName: courseName,
      gigDescription: courseDescription,
      createdBy: loggedinUserId,
      searchTags: searchTags,
      category: category,
      subCategory: subCategory,
      gigImage: gigImage,
      shortVideoUrl: shortVideoUrl,
      serviceProviderDisplayName: userDetails?.displayName,
      serviceProviderId: loggedinUserId,
      serviceProviderName: userDetails?.firstName,
      relatedImages: relatedImages,
      profileImage: userDetails?.profileImage,
      gigPrice: gigPrice,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      gender: CreateCourseObj?.gender?.map((each) => each?.toLowerCase()),
      localGigPrice,
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      summary: summary,
      discountEndDate: moment(discountEndDate)?.format("YYYY-MM-DDT23:59:59"),
      discountStartDate,
      discountType,
      gigVideos,
      languages,
      targetAgeGroups,
      courseLevel: formatSrings(courseLevel),
      currencyCode: userDetails?.transactionCurrencyCode || "",
      approvalStatus: "pending",
      isRefundAvailable: CreateCourseObj?.isRefundAvailable
        ? CreateCourseObj?.isRefundAvailable
        : false,
      refundConditions: CreateCourseObj?.refundConditions
        ? CreateCourseObj?.refundConditions
        : [],
    };
    const gigObjectSend = {
      serviceProviderId: loggedinUserId,
      gender: CreateCourseObj?.gender?.map((each) => each?.toLowerCase()),
      createdBy: loggedinUserId,
      courseLevel: formatSrings(courseLevel),
      category: category,
      subCategory: subCategory,
      languages: languages,
      courseType: courseType,
      targetAgeGroups: targetAgeGroups,
      gigName: courseName,
      gigDescription: courseDescription,
      summary: summary,
      gigImage: gigImage,
      relatedDocuments: editGig ? editGig?.relatedDocuments : [],
      searchTags: searchTags,
      shortVideoUrl: shortVideoUrl,
      thumbnail: "",
      gigVideos: gigVideos,
      gigPrice: gigPrice,
      discountPrice: convertINRtoUSD(gigDiscountPrice || 0),
      discountStartDate: discountStartDate,
      discountEndDate: moment(discountEndDate)?.format("YYYY-MM-DDT23:59:59"),
      discountType: discountType,
      approvalStatus: "pending",
      gigDiscountValue:
        discountType === "percentage"
          ? localGigDiscountValue
          : convertINRtoUSD(localGigDiscountValue || 0),
      allowOutside: false,
      localGigPrice,
      localDiscountPrice: gigDiscountPrice,
      localGigDiscountValue,
      currencyCode: editGig?.currencyCode || userCountry?.countryCode,
      isRefundAvailable: CreateCourseObj?.isRefundAvailable
        ? CreateCourseObj?.isRefundAvailable
        : false,
      refundConditions: CreateCourseObj?.refundConditions
        ? CreateCourseObj?.refundConditions
        : [],
    };

    if (editGig) {
      saveDraftGig(
        { _id: editGig?._id, ...gigObjectSend },
        (res) => {
          if (res.result !== null) {
            showToast("success", res?.message);
            setGigObject(gb);
            setCreateCourseObj(obj);
            dispatch(editGigObject(null));
            localStorage.removeItem("CreateCourseObj");
            navigate("/view-gigs", { state: { isDraft: true }, replace: true });
            setSaveApiLoad(false);
          } else {
            showToast("info", res?.message);
            setSaveApiLoad(false);
          }
        },
        (error) => {
          console.log(error, "error-save-gig");
          setSaveApiLoad(false);
        }
      );
    } else {
      saveDraftGig(
        { ...gigObj },
        (res) => {
          if (res.result !== null) {
            showToast("info", "Waiting for admin approval");
            setGigObject(gb);
            setCreateCourseObj(obj);
            localStorage.removeItem("CreateCourseObj");
            navigate("/view-gigs", { state: { isDraft: true }, replace: true });
            setSaveApiLoad(false);
          } else {
            showToast("info", res?.message);
            setSaveApiLoad(false);
          }
        },
        (error) => {
          console.log(error, "error-save-gig");
          setSaveApiLoad(false);
        }
      );
    }
  };

  const formatSrings = (upcomingArray) => {
    // Sort the upcomingArray based on the order of baseArray
    const sortedUpcomingArray = [...upcomingArray].sort((a, b) => {
      return levelStrings.indexOf(a) - levelStrings.indexOf(b);
    });
    // Compare the sorted upcomingArray with baseArray
    return JSON.stringify(sortedUpcomingArray) === JSON.stringify(levelStrings)
      ? ["All Levels"]
      : sortedUpcomingArray;
  };

  const formatArrayToAgeGroupOrder = (upcomingArray) => {
    return [...(upcomingArray || [])].sort((a, b) => {
      return targetedAgeGroup?.indexOf(a) - targetedAgeGroup.indexOf(b);
    });
  };

  // Function to update recurrencyType based on non-empty arrays
  const updateRecurrencyType = (data) => {
    let recurrencyType = [...data?.recurrenceType];
    // Check for weekly recurrence types
    if (data.individualWeekly.length > 0 || data.groupWeekly.length > 0) {
      if (!recurrencyType.includes("weekly")) {
        recurrencyType.push("weekly"); // Add "weekly" if either weekly array has data
      }
    }

    // Check for daily recurrence types
    if (data.individualDaily.length > 0 || data.groupDaily.length > 0) {
      if (!recurrencyType.includes("daily")) {
        recurrencyType.push("daily"); // Add "daily" if either daily array has data
      }
    }

    return recurrencyType.sort();
  };

  // console.log(CreateCourseObj, "create");

  const saveCourseApi = () => {
    setSaveApiLoad(true);
    if (cloneCourse) {
      delete CreateCourseObj?._id;
      if (CreateCourseObj?.deleted) {
        delete CreateCourseObj?.deleted;
      }
    }
    let saveObject = {
      ...CreateCourseObj,
      approvalStatus: "pending",
      currencyCode: userDetails?.transactionCurrencyCode || "",
      endDate: moment(CreateCourseObj?.endDate)?.format("YYYY-MM-DDT00:00:00"),
      discountEndDate: moment(CreateCourseObj?.discountEndDate)?.format(
        "YYYY-MM-DDT23:59:59"
      ),
    };
    saveObject.gender = saveObject?.gender?.map((each) => each?.toLowerCase());

    saveObject.individualDaily = formatDatesAndTimes(
      saveObject?.individualDaily
    );

    const { groupDaily, groupWeekly, individualWeekly, individualDaily } =
      CreateCourseObj;

    // Combine all arrays into one
    const combinedArray = [
      ...groupDaily,
      ...groupWeekly,
      ...individualDaily,
      ...individualWeekly,
    ];
    // Check if any object has `virtual` set to true
    const virtual = combinedArray.some((each) => each?.virtual === true);

    // Check if any object has `inPerson` set to true
    const inPerson = combinedArray.some((each) => each?.inPerson === true);
    saveObject.virtual = virtual;
    saveObject.inPerson = inPerson;

    saveObject.courseLevel = formatSrings(CreateCourseObj?.courseLevel);
    saveObject.targetAgeGroups = formatArrayToAgeGroupOrder(
      CreateCourseObj?.targetAgeGroups
    );
    saveObject.recurrenceType = updateRecurrencyType(saveObject);
    saveObject.groupDaily = formatDatesAndTimes(saveObject?.groupDaily);
    saveObject.groupWeekly = formatDatesAndTimes(saveObject?.groupWeekly);
    saveObject.courseType = sortClassType(saveObject?.courseType);
    saveObject.individualWeekly = formatDatesAndTimes(
      saveObject?.individualWeekly
    );
    if (saveObject?.lessonPlan?.sessions?.length == 0) {
      delete saveObject.lessonPlan;
    } else {
      saveObject.lessonPlan.serviceProviderId = loggedinUserId;
      saveObject.lessonPlan.category = saveObject?.category;
      saveObject.lessonPlan.subCategory = saveObject?.subCategory;
      saveObject.lessonPlan.level = saveObject?.level;
      saveObject.lessonPlan.noOfSessions =
        saveObject?.lessonPlan?.sessions?.length;
      let nuOfTopics = 0;
      saveObject?.lessonPlan?.sessions?.map((each) => {
        nuOfTopics = nuOfTopics + each?.topics?.length;
        return each;
      });
      saveObject.lessonPlan.noOfTopics = nuOfTopics;
    }
    if (!saveObject?.isRefundAvailable) {
      saveObject = {
        ...saveObject,
        isRefundAvailable: false,
        refundConditions: [],
      };
    }
    // console.log(saveObject, "saveObject");
    if (isGig) {
      saveGigApi();
    } else {
      const pricesNotZero =
        checkPrice("groupPrice", CreateCourseObj?.groupWeekly) &&
        checkPrice("groupPrice", CreateCourseObj?.groupDaily) &&
        checkPrice("individualDaily", CreateCourseObj?.individualDaily) &&
        checkPrice("individualWeekly", CreateCourseObj?.individualWeekly);
      if (draftCourse) {
        updateDraftCourse(
          saveObject,
          (response) => {
            showToast("info", response?.message);
            navigate("/my-courses", {
              state: { isDraft: true },
              replace: true,
            });
            dispatch(setDraftCourseObject(null));
            dispatch(editCourseObject(null));
            setSaveApiLoad(false);
          },
          (err) => {
            console.log(err);
            setSaveApiLoad(false);
          }
        );
      } else if (pricesNotZero) {
        saveDraftCourse(
          saveObject,
          (response) => {
            showToast("info", "Waiting for admin approval");
            localStorage.removeItem("CreateCourseObj");
            navigate("/my-courses", {
              state: { isDraft: true },
              replace: true,
            });
            dispatch(setDraftCourseObject(null));
            dispatch(editCourseObject(null));
            setSaveApiLoad(false);
          },
          (err) => {
            console.log(err);
            setSaveApiLoad(false);
          }
        );
      } else {
        showToast("error", "Batch Price Cannot be zero");
      }
    }
  };

  function checkSlots(categories) {
    const keysToCheck = {
      Individual: ["individualWeekly", "individualDaily"],
      Group: ["groupDaily", "groupWeekly"],
    };

    if (isGig) {
      if (onlyPriceConvert(5).toFixed() > gigObject?.localGigPrice) {
        return false;
      }
    } else {
      if (isEmpty(CreateCourseObj?.courseType)) {
        return false;
      }
      for (const category of CreateCourseObj?.courseType) {
        const keys = keysToCheck[category] || [];
        let hasData = false;
        for (const key of keys) {
          if (CreateCourseObj[key] && CreateCourseObj[key]?.length > 0) {
            hasData = true;
            break;
          }
        }
        if (!hasData) {
          return false;
        }
      }
    }

    return true;
  }

  const checkSlot = checkSlots();

  // console.log(CreateCourseObj, "courseObj,-");

  const returnActiveTabContent = (each) => {
    const showContent =
      each?.name == "Lesson Plan" && !isEmpty(CreateCourseObj?.lessonPlan);
    if (each?.name == "Title" && !isButtonEnabled && activeField != "Title") {
      return (
        <ActiveOverviewTab
          object={CreateCourseObj}
          {...{ setActiveField, isGig }}
        />
      );
    }
    return (
      <Tab
        each={each}
        {...{ CreateCourseObj, showContent, editCourse, checkSlot, isGig }}
        activeField={activeField}
        setActiveField={(tab) => {
          validateObj(CreateCourseObj);
          setActiveField(tab);
          // const activeConditons = isGig
          //   ? !returnBool
          //   : each?.name == "Course Type & Pricing" &&
          //     CreateCourseObj?.courseImage !== "" &&
          //     CreateCourseObj?.relatedImages?.length >= 2;
          // const errObj =
          //   each?.name !== "Title" ? validateObj(CreateCourseObj) : {};
          // if (!isEmpty(errObj?.errors)) {
          //   setActiveField("Title");
          // } else if (!isEmpty(errObj?.imageErrors)) {
          //   setActiveField("Course Image");
          // } else {
          //   if (
          //     each?.name == "Title" ||
          //     editCourse ||
          //     cloneCourse ||
          //     draftCourse ||
          //     showContent ||
          //     prevData !== null ||
          //     editGig
          //   ) {
          //     setActiveField(tab);
          //   } else if (each?.name == "Lesson Plan" && !isButtonEnabled) {
          //     setActiveField(tab);
          //   } else if (activeConditons) {
          //     setActiveField(tab);
          //   }
          // }
        }}
      />
    );
  };

  const handleSaveRefundDetails = (conditions) => {
    let refundAvailability = conditions.some((each) => each?.isSelected);
    changeCreateObj(refundAvailability, "isRefundAvailable");
    changeCreateObj(conditions, "refundConditions");
    setRefundConditions(
      editCourse
        ? editCourse?.refundConditions || []
        : userDetails?.courseRefundConditions[0]?.refundConditions || []
    );
    setOpenRefundConditions(false);
  };

  const returnActImage = () => {
    let bool = false;
    if (isGig) {
      bool =
        gigObject?.gigImage !== "" &&
        gigObject?.shortVideoUrl !== "" &&
        !isEmpty(gigObject?.gigVideos);
    } else {
      bool =
        CreateCourseObj?.courseImage !== "" &&
        CreateCourseObj?.relatedImages?.length >= 2;
    }

    return bool;
  };

  return (
    <AppContainer
      className={"app-container"}
      height={mobileDevices ? "auto" : "90vh"}
    >
      <div
        className="add-container"
        style={{
          display: mobileDevices || ipodDevices ? "flex" : "",
          flexDirection: mobileDevices || ipodDevices ? "row" : "",
          justifyContent: mobileDevices || ipodDevices ? "space-between" : "",
          alignItems: mobileDevices || ipodDevices ? "center" : "",
        }}
      >
        <button
          onClick={() => {
            // handleSaveDraft()

            if (!miniFields) {
              setShowDraftPop(true);
            } else {
              if (isGig) {
                navigate("/view-gigs", {
                  replace: true,
                });
              } else {
                navigate("/my-courses", {
                  replace: true,
                });
              }
            }
          }}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
          }}
        >
          <IoIosArrowBack size={mobileDevices ? 18 : 22} />
        </button>

        <Box display={"flex"} flexDirection={"row"} gap={2}>
          <button
            style={{
              display: !mobileDevices && !ipodDevices && "none",
              background: "none",
              border: "none",
            }}
            onClick={() => {
              // handleSaveDraft()
              if (miniFields) {
                showToast("info", "Title, level & category are required");
              } else {
                draftCourse2(CreateCourseObj);
              }
            }}
            disabled={miniFields}
          >
            <AautiText
              title={"Save as Draft"}
              textStyle={{
                color: miniFields ? "lightgrey" : "#5C5B57",
                textDecoration: "underline",
              }}
            />
          </button>
          <button
            style={{
              display: !mobileDevices && !ipodDevices && "none",
              background: "none",
              border: "none",
            }}
            onClick={() => {
              // handleSaveDraft()

              if (!miniFields) {
                setDiscardChanges(true);
              } else {
                if (isGig) {
                  navigate("/view-gigs", {
                    replace: true,
                  });
                } else {
                  navigate("/my-courses", {
                    replace: true,
                  });
                }
              }
            }}
          >
            <AautiText
              title={"Discard"}
              textStyle={{
                color: "#5C5B57",
                textDecoration: "underline",
              }}
            />
          </button>
        </Box>
      </div>
      <div className="main-container-creation">
        <AautiText
          size="bigSize"
          weight={"extraBold"}
          title={
            isGig ? AautiStrings.GigCreation : AautiStrings.CourseCreationString
          }
          textStyle={{ marginBottom: 1 }}
        />
        <AautiText
          textStyle={{ color: "#5C5B57" }}
          title={
            isGig
              ? courseConstant?.Createagigwithclearobjectivesanddesignedcontentaimedtoenhanceskill
              : courseConstant?.Createacoursewithclearobjectivesandengaginginteractivecontenttoenhanceskills
          }
        />

        <div className="tabs-container">
          {itemsWithNewComponent?.map((each, index) => {
            return (
              <div
                key={index}
                style={{
                  display: each?.name === "Lesson Plan" && isGig && "none",
                }}
              >
                {each?.isComponent ? (
                  <>
                    {returnActImage() && activeField !== "Course Image" ? (
                      <CourseImages
                        {...{ setActiveField, gigObject, isGig }}
                        imagesList={[
                          CreateCourseObj?.courseImage || gigObject?.gigImage,
                          ...CreateCourseObj?.relatedImages,
                        ]}
                      />
                    ) : (
                      <div
                        className="tab image-upload-cont"
                        style={{
                          display: activeField === "Course Image" && "none",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        onClick={() => {
                          validateObj(CreateCourseObj);
                          setActiveField("Course Image");
                          // const errObj = validateObj(CreateCourseObj);
                          // if (isEmpty(errObj?.errors)) {
                          //   if (editGig) {
                          //     setActiveField("Course Image");
                          //   } else if (
                          //     CreateCourseObj?.lessonPlan?.contentName
                          //   ) {
                          //     setActiveField("Course Image");
                          //   } else if (
                          //     !checkNull(gigObject?.gigImage) ||
                          //     !checkNull(CreateCourseObj.courseImage)
                          //   ) {
                          //     setActiveField("Course Image");
                          //   }
                          // } else {
                          //   setActiveField("Title");
                          // }
                        }}
                      >
                        <div className="text-icons">
                          <AautiText
                            size="big"
                            weight={"bold"}
                            title={
                              isGig
                                ? AautiStrings.GigImage
                                : AautiStrings.CourseImagesString
                            }
                            textStyle={{ marginBottom: 5, width: "80%" }}
                          />
                          <div className="icons-flex">
                            <GoDotFill
                              color={
                                (CreateCourseObj?.courseImage !== "" &&
                                  CreateCourseObj?.relatedImages?.length >=
                                    2) ||
                                gigObject.gigImage !== ""
                                  ? "green"
                                  : "red"
                              }
                            />
                            <IconButton>
                              <FaArrowRight size={20} />
                            </IconButton>
                          </div>
                        </div>
                        <AautiUpload
                          id="upload media"
                          disabled={true}
                          accept="image/*,"
                          //   onChange={(e) => {
                          //     // checkImageRatios(e, "courseImage", true);
                          //   }}s
                          iconName={AautiStrings.CoverImageString}
                          important
                          customLabel={
                            <div className="upload-label">
                              <LuUpload size={35} color="#3083EF" />
                              <AautiText
                                size={"semi"}
                                weight={"normal"}
                                textStyle={{ color: "#000" }}
                                title={AautiStrings.UploadImagesOrFilesString}
                              />
                            </div>
                          }
                        />
                      </div>
                    )}
                    {activeField === "Course Image" && returnActiveContent()}
                  </>
                ) : (
                  <>
                    {returnActiveTabContent(each)}
                    {activeField === each.name && returnActiveContent()}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="add-container save-cont">
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <button
            onClick={() => {
              // handleSaveDraft()
              const bool =
                !CreateCourseObj.courseName?.length ||
                !CreateCourseObj.courseLevel?.length ||
                !CreateCourseObj?.category?.length ||
                !CreateCourseObj?.subCategory?.length;
              if (!bool) {
                setDiscardChanges(true);
              } else {
                if (isGig) {
                  navigate("/view-gigs", {
                    replace: true,
                  });
                } else {
                  navigate("/my-courses", {
                    replace: true,
                  });
                }
              }
            }}
            style={{
              background: "none",
              border: "1px solid #5C5B57",
              padding: "10px",
              borderRadius: 5,
              cursor: "pointer",
              display: mobileDevices || ipodDevices ? "none" : "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
              width: "170px",
            }}
          >
            <AautiText title={"Discard"} textStyle={{ color: "#5C5B57" }} />
          </button>

          <button
            onClick={() => {
              // handleSaveDraft()
              if (miniFields) {
                showToast("info", "Title, level & category are required");
              } else {
                draftCourse2(CreateCourseObj);
              }
            }}
            disabled={miniFields}
            style={{
              background: "none",
              border: miniFields ? "1px solid lightgrey" : "1px solid #5C5B57",
              padding: "10px",
              borderRadius: 5,
              cursor: "pointer",
              display: mobileDevices || ipodDevices ? "none" : "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
              width: "170px",
            }}
          >
            <AautiText
              title={"Save as Draft"}
              textStyle={{ color: miniFields ? "lightgrey" : "#5C5B57" }}
            />
          </button>
        </Box>
        <div style={{ width: "100%" }}>
          <div className="submit-btn-container">
            <JoinButton
              onClick={() => {
                setShowPreview(true);
              }}
              disabled={
                !checkSlot ||
                (isGig && !gigObject?.gigPrice) ||
                isButtonEnabled ||
                !isEmpty(courseOverviewErrors) ||
                !isEmpty(courseImageErrors)
              }
              title={courseConstant?.previewCourse}
              outlined
              buttonStyle={{
                marginBottom: 8,
                // display: (editGig || isGig) && "none",
              }}
              width="170px"
            />
            <JoinButton
              buttonStyle={{ marginBottom: 8 }}
              loading={saveApiLoad}
              disabled={
                // activeField != "Course Type & Pricing" ||
                !checkSlot ||
                isButtonEnabled ||
                (isGig && !gigObject?.gigPrice) ||
                sameObjects ||
                !isEmpty(courseOverviewErrors) ||
                !isEmpty(courseImageErrors)
              }
              title={AautiStrings.SubmitForReviewString}
              onClick={() => {
                const errObj = validateObj(CreateCourseObj);
                if (!isEmpty(errObj?.errors)) {
                  setActiveField("Title");
                } else if (!isEmpty(errObj?.imageErrors)) {
                  setActiveField("Course Image");
                } else {
                  setShowDisclaimer(true);
                }
              }}
              width="170px"
            />
          </div>
          <HelperText
            info={true}
            title={courseConstant?.onceSubmittedItWillBeUnderReview}
          />
        </div>
      </div>
      <BottomSheet
        open={openAddress}
        onClose={(e) => {
          e.stopPropagation();
          if (CreateCourseObj?.isClientLocation && !CreateCourseObj?.radius) {
            showToast("info", "Client location radius cannot be 0");
          } else {
            setOpenAddress(false);
          }
        }}
      >
        <CourseInPerson
          handleSelectAddress={(item) => {
            changeCreateObj(defaultAddress?.[0] || {}, "address");
            setSelectedAddress(item);
          }}
          selectedAddress={selectedAddress}
          CreateCourseObj={CreateCourseObj}
          changeCreateObj={changeCreateObj}
          isClientLocation="isClientLocation"
          {...{ handleSetSelectAddress, setCreateCourseObj }}
        />
      </BottomSheet>
      <BottomSheet
        dialogTitle="Preview"
        direction="bottom"
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        {isGig ? (
          <Box mt={1}>
            <PreviewGigSubmit
              {...{ gigObject: { ...CreateCourseObj, ...gigObject } }}
            />
          </Box>
        ) : (
          <PreviewCourse object={CreateCourseObj} {...{ setShowPreview }} />
        )}
      </BottomSheet>
      <AautiDialog
        open={openRefundConditions}
        onClose={() => {
          setOpenRefundConditions(false);
        }}
        dialogTitle={AautiStrings.RefundsString}
        titlePosition="left"
        sxStyles={{ padding: "20px 20px 20px 20px" }}
      >
        {openRefundConditions && (
          <RefundConditions
            isRefundAvailable={CreateCourseObj?.isRefundAvailable}
            refundConditions={refundConditions}
            handleSaveRefundDetails={handleSaveRefundDetails}
          />
        )}
      </AautiDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AautiDialog
        // minWidth={300}
        dialogTitle={courseConstant?.conflicts}
        open={conflictsList}
        onClose={() => {
          setConflictsList(null);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 5,
          }}
        >
          {conflictsList?.map((each, index) => {
            return <ConflictCard {...{ each }} />;
          })}
        </div>
      </AautiDialog>
      <AautiDialog
        maxWidth={"sm"}
        open={showDisclaimer || discardChanges}
        onClose={() => {
          setShowDisclaimer(false);
          setDiscardChanges(false);
        }}
      >
        <AautiText
          size={"normal"}
          title={
            discardChanges
              ? "Please note that your changes may not be saved"
              : AautiStrings.YouAreResponsibleForTheContentYouHaveUploadedString
          }
          textStyle={{ color: "#000" }}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gap={1}
          mt={2}
        >
          {discardChanges && (
            <NavButton
              testId={"discard_save_draft"}
              outlined
              text={"Save as Draft"}
              handleClick={() => {
                draftCourse2(CreateCourseObj);
                setDiscardChanges(false);
              }}
            />
          )}
          <NavButton
            loading={saveApiLoad}
            text={discardChanges ? "Discard" : AautiStrings.OkString}
            handleClick={() => {
              if (!discardChanges) {
                setShowDisclaimer(false);
                saveCourseApi();
              } else {
                if (isGig) {
                  navigate("/view-gigs", {
                    replace: true,
                  });
                } else {
                  navigate("/my-courses", {
                    replace: true,
                  });
                }
                setDiscardChanges(false);
              }
            }}
          />
        </Box>
      </AautiDialog>
      <AautiDialog
        maxWidth={"sm"}
        open={showDraftPop}
        onClose={() => {
          setShowDraftPop(false);
        }}
      >
        <AautiText
          size={"normal"}
          title={"Kindly note that your changes may not be saved"}
          textStyle={{ color: "#000" }}
        />

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gap={1}
          mt={1}
        >
          <NavButton
            text={"Save as Draft"}
            handleClick={() => {
              draftCourse2(CreateCourseObj);
              setShowDraftPop(false);
            }}
            outlined={true}
          />
          <NavButton
            text={"Leave"}
            handleClick={() => {
              setShowDraftPop(false);
              if (isGig) {
                navigate("/view-gigs", {
                  replace: true,
                });
                dispatch(editGigObject(null));
              } else {
                navigate("/my-courses", {
                  replace: true,
                });
                dispatch(setDraftCourseObject(null));
                dispatch(editCourseObject(null));
              }
            }}
          />
        </Box>
      </AautiDialog>
      <ReturnPolicy
        {...{
          open: refundPolicyState,
          setOpen: setRefundPolicyState,
          showFooter: false,
        }}
      />
    </AppContainer>
  );
};

export default CourseCreation;
