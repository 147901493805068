const globalClassWindows = new Map();

function restoreWindowsFromLocalStorage(name) {
  try {
    const savedWindows = JSON.parse(
      localStorage.getItem("classWindowRefs") || "{}"
    );

    Object.entries(savedWindows).forEach(([key, windowInfo]) => {
      if (key.includes(name)) {
        const existingWindow = window.open("", key);
        if (existingWindow && !existingWindow.closed) {
          globalClassWindows.set(key, existingWindow);
        } else {
          globalClassWindows.delete(key);
        }
      }
    });
  } catch (error) {
    localStorage.removeItem("classWindowRefs");
  }
}

function updateLocalStorage() {
  const savedWindows = {};
  globalClassWindows.forEach((win, uniqueName) => {
    if (win && !win.closed) {
      savedWindows[uniqueName] = { name: uniqueName, timestamp: Date.now() };
    }
  });
  localStorage.setItem("classWindowRefs", JSON.stringify(savedWindows));
}

function openClassWindow({ path = "", room = "" }) {
  const strWindowFeatures = "location=yes,scrollbars=yes,status=yes";
  const joinClassPath = `${path}/join_class/aautimeet`;
  const uniqueName = `${room}`;
  restoreWindowsFromLocalStorage(uniqueName);

  const existingWindow = globalClassWindows.get(uniqueName);

  if (existingWindow && !existingWindow.closed) {
    existingWindow.focus();
  } else {
    const newWindow = window.open(joinClassPath, uniqueName, strWindowFeatures);

    if (newWindow) {
      globalClassWindows.set(uniqueName, newWindow);
      updateLocalStorage();

      newWindow.onload = () => {
        newWindow.document.title = room;
      };

      newWindow.addEventListener("beforeunload", () => {
        globalClassWindows.delete(uniqueName);
        updateLocalStorage();
      });
    }
  }
}

export { openClassWindow };
