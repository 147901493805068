import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";

const UploadPreview = ({
  id = "",
  index,
  each = "",
  removeRelatedImages = () => {},
  changeCreateObj = () => {},
  removeCoverPage = () => {},
  setShowPrevObj,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <Tooltip title="Preview" placement="bottom">
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowPrevObj({ bool: true, base: id, url: each });
        }}
        style={{
          border: "1px solid lightgrey",
          borderRadius: 6,
          padding: 5,
          position: "relative",
          height: 120,
          width: 130,
          cursor: "pointer",
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (id == "courseImage") {
              changeCreateObj("", "courseImage");
            } else if (id == "bookCoverPage") {
              removeCoverPage(index);
            } else {
              removeRelatedImages(index);
            }
          }}
          sx={{
            position: "absolute",
            right: 1,
            background: "#fff",
            "&:hover": {
              background: "#fff",
            },
          }}
        >
          <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
        </IconButton>
        <img
          src={each}
          style={{ height: "100%", width: "100%" }}
          alt="gigImage"
        />
      </div>
    </Tooltip>
  );
};

export default UploadPreview;
